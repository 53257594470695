import React, { useRef, useState, useEffect } from "react"

import valentinesMatchstick from "../images/Matchmaker 21 Logo.png"
import valentinesLogo from "../images/matchmaker-provo-name.png"
import Footer from "./footer"
import CountDown from "./countdown"

function StartButton({ school }) {
  return (
    <div
      className="start-btn p-5"
      onClick={() => {
        if (school === "BYU") {
          /*window.location.replace(
            `https://cas.byu.edu/cas/login?service=${process.env.GATSBY_MATCHMAKER_URI}?school=${school}`
          )*/
          window.location.replace(
            `${process.env.GATSBY_MATCHMAKER_URI}/byuauth`
          )
        } else if (school === "UVU") {
          window.location.replace(
            `${process.env.GATSBY_MATCHMAKER_URI}/uvuauth`
          )
        }
      }}
    >
      <div className="b-reg text-sm md:text-2xl">TAKE THE SURVEY AS A</div>
      <div className="b-reg text-lg md:text-5xl">{school} STUDENT</div>
    </div>
  )
}

function useHover() {
  const [value, setValue] = useState(false)

  const ref = useRef(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener("mouseover", handleMouseOver)
        node.addEventListener("mouseout", handleMouseOut)

        return () => {
          node.removeEventListener("mouseover", handleMouseOver)
          node.removeEventListener("mouseout", handleMouseOut)
        }
      }
    },
    [ref.current] // Recall only if ref changes
  )

  return [ref, value]
}

function GreyLine() {
  return (
    <div
      className="mt-2"
      style={{ width: "37%", height: "2px", backgroundColor: "#707070" }}
    ></div>
  )
}

function CASExplanation() {
  const [hoverRef, isHovered] = useHover()
  return (
    <div
      className={`${
        isHovered ? "animation-slide-up" : "h-36"
      } flex flex-col w-full`}
      ref={hoverRef}
    >
      <div className="flex justify-between w-full">
        <GreyLine></GreyLine>
        <div className="b-reg text-xs theme-valentines-red">
          {isHovered
            ? "LOOK NO FURTHER!"
            : "CURIOUS ABOUT OUR CAS VERIFICATION?"}
        </div>
        <GreyLine></GreyLine>
      </div>
      <div className={`theme-gray b-reg text-xs ${isHovered ? "" : "hidden"}`}>
        <p>
          WE USE YOUR SCHOOL'S ACCESS SYSTEM ONLY TO VERIFY STUDENT STATUS - IN
          THE SAME WAY AS POPULAR SITES LIKE REFWORKS OR EBSCO.
        </p>
        <p>
          WE PROMISE NONE OF US NOR OUR ALGORITHMS, WEBSITES, FLYING MONKEYS, OR
          OTHER CRONIES WILL EVER COME IN CONTACT WITH YOUR SCHOOL PASSWORD OR
          ANY OTHER PRIVATE ACCOUNT INFORMATION.
        </p>
      </div>
    </div>
  )
}

export default function ValentinesLanding({
  onAnimationEnd,
  className,
  showVerify,
}) {
  const [fadeOutIsComplete, setFadeOutIsComplete] = useState(false)

  function finishAnimation() {
    setFadeOutIsComplete(true)
    onAnimationEnd()
  }

  const launch_date = new Date(2020, 9, 25, 0, 1, 0, 0)
  const MATCH_DROP_DATE = new Date(2020, 10, 13, 23, 59, 0)

  return (
    <div
      className={
        "max-w-6xl m-auto flex justify-center flex-col align-middle " +
        (fadeOutIsComplete ? "hidden" : className)
      }
      onAnimationEnd={finishAnimation}
    >
      <div className="mt-20 md:mt-48">
        <center>
          <div className="responsive-1">
            <img
              className="matchstick-1 px-10"
              src={valentinesMatchstick}
              alt="matchstick"
            ></img>
            <div className="logo-1 flex flex-col justify-center">
              <img className="mt-10 md:mt-20" src={valentinesLogo}></img>
              {showVerify ? (
                <div className="flex justify-center h-40 md:h-48 pt-10">
                  <StartButton school="UVU"></StartButton>
                  <div
                    style={{
                      width: "2px",
                      height: "100%",
                      backgroundColor: "#D05476",
                    }}
                  ></div>
                  <StartButton school="BYU"></StartButton>
                </div>
              ) : (
                <div className="b-reg theme-gray text-sm md:text-2xl">
                  Verifying student status...
                </div>
              )}
              <br></br>
              <br></br>
              <CASExplanation></CASExplanation>
            </div>
          </div>
        </center>
      </div>
      <div></div>
      <Footer
        className={`${
          process.env.GATSBY_ALLOW_THROUGH_COUNTDOWN === "false" ? "hidden" : ""
        }`}
      ></Footer>
    </div>
  )
}
