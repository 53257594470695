import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import TextPlayer from "../components/text-player"
import Survey from "../components/survey/survey"
import Landing from "../components/valentines-landing"

export default function MatchmakerBYU() {
  const [username, setUsername] = useState("")
  const [sessionId, setSessionId] = useState("")
  const [questions, setQuestions] = useState([])
  const [initialAnswers, setInitialAnswers] = useState([])
  const [hasTicket, setHasTicket] = useState(false)
  const [skipToSurvey, _] = useState(
    process.env.GATSBY_SKIP_TO_SURVEY === "true"
  )
  const [isRetaking, setIsRetaking] = useState(false)
  const [showLanding, setShowLanding] = useState(true)
  const [showTutorial, setShowTutorial] = useState(false)
  const [showSurvey, setShowSurvey] = useState(
    process.env.GATSBY_SKIP_TO_SURVEY === "true"
  )

  function startQuiz() {
    setShowTutorial(false)
    setShowSurvey(true)
  }

  function handleClick() {
    if (
      showLanding &&
      hasTicket &&
      process.env.GATSBY_ALLOW_THROUGH_COUNTDOWN === "true"
    ) {
      setShowLanding(false)
    }
  }

  function landingPageFadeDone() {
    if (isRetaking) {
      setShowTutorial(false)
      setShowSurvey(true)
    } else {
      setShowTutorial(true)
    }
  }

  function shuffleQuestions(questions) {
    let valuesQuestions = questions.slice(16, 70)
    // The target question is index 25
    let unusualQ = valuesQuestions.splice(10, 1)[0]
    var j, x, i
    for (i = valuesQuestions.length - 1; i > 0; i--) {
      j = Math.floor(Math.random() * (i + 1))
      x = valuesQuestions[i]
      valuesQuestions[i] = valuesQuestions[j]
      valuesQuestions[j] = x
    }
    valuesQuestions = [
      ...valuesQuestions.slice(0, 28),
      unusualQ,
      ...valuesQuestions.slice(28),
    ]
    return [
      ...questions.slice(0, 16),
      ...valuesQuestions,
      ...questions.slice(70),
    ]
  }

  function authorize(ticket, school) {
    const apiAuth = school === "BYU" ? "auth_byu" : "auth_uvu"
    fetch(`${process.env.GATSBY_FIREBASE_URI}/${apiAuth}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ticket: ticket + "",
        service: `${process.env.GATSBY_MATCHMAKER_URI}?school=${school}`,
      }),
    })
      .then(res => res.json())
      .then(data => {
        const { authenticated, hasTakenSurvey, error } = data
        if (hasTakenSurvey) {
          window.location.replace(
            `${process.env.GATSBY_MATCHMAKER_URI}/done?sessionId=${data.sessionId}`
          )
        } else if (authenticated) {
          const { sessionId, username, questions } = data
          let shuffledQuestions = shuffleQuestions(questions)
          setSessionId(sessionId)
          setUsername(username)
          setQuestions(shuffledQuestions)
          setShowLanding(false)
        } else {
          if (error === "invalid ticket") {
            setHasTicket(false)
          } else {
            alert(
              "Sorry, Matchmaker has encountered an unexpected error.  Please try again in an hour."
            )
          }
        }
      })
  }

  function retake(sessionId) {
    fetch(`${process.env.GATSBY_FIREBASE_URI}/retake`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        sessionId: sessionId,
      }),
    })
      .then(res => res.json())
      .then(data => {
        const { authenticated } = data
        if (authenticated) {
          const { username, sessionId, questions, response } = data
          setSessionId(sessionId)
          setUsername(username)
          setIsRetaking(true)
          setQuestions(questions)
          setInitialAnswers(response)
          setShowLanding(false)
        } else {
        }
      })
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)
    if (process.env.GATSBY_USE_AUTH === "true") {
      const params = new URLSearchParams(window.location.search)
      const ticket = params.get("ticket")
      const sessionId = params.get("sessionId")
      const school = params.get("school")
      setHasTicket(ticket !== null || sessionId !== null)
      if (ticket !== null) {
        authorize(ticket, school)
      } else if (sessionId !== null) {
        retake(sessionId)
      }
    } else {
      setHasTicket(true)
      authorize("GOLDEN_TICKET")
    }
  }, [])

  return (
    <Layout>
      <div className="m-auto max-w-6xl" onClick={handleClick}>
        <Landing
          className={
            skipToSurvey ? "hidden" : showLanding ? "" : "animate-fade-out"
          }
          onAnimationEnd={landingPageFadeDone}
          showVerify={!hasTicket}
        ></Landing>
        <TextPlayer
          onComplete={startQuiz}
          className={showTutorial ? "animate-fade-in" : "hidden"}
          textGroups={[
            [
              <p className="text-copy-mobile md:text-copy">hey there!</p>,
              <p className="text-copy-mobile md:text-copy">
                welcome to{" "}
                <span className="text-emphasis-mobile md:text-emphasis">
                  Matchmaker Provo!
                </span>
              </p>,
            ],
            [
              <p className="text-copy-mobile md:text-copy">byu</p>,
              <p className="text-copy-mobile md:text-copy">and this time</p>,
              <p className="text-copy-mobile md:text-copy">uvu as well</p>,
              <p className="text-copy-mobile md:text-copy">are you ready</p>,
              <p className="text-copy-mobile md:text-copy">
                <span className="text-emphasis-mobile md:text-emphasis">
                  to find your valentine?
                </span>
              </p>,
            ],
            [
              <p className="text-left text-answer-mobile md:text-answer">
                The quiz is simple!
                <span className="text-emphasis-sm-mobile md:text-emphasis-sm">
                  {" "}
                  &nbsp; 80 questions
                </span>
                — all multiple choice — will give us all the information we need
                to run our
                <span className="text-emphasis-sm-mobile md:text-emphasis-sm">
                  {" "}
                  algorithm magic
                </span>{" "}
                and find the
                <span className="text-emphasis-sm-mobile md:text-emphasis-sm">
                  {" "}
                  4 people{" "}
                </span>
                at
                <span className="text-emphasis-sm-mobile md:text-emphasis-sm">
                  {" "}
                  BYU or UVU{" "}
                </span>
                who match with you in all the most important ways.
              </p>,
            ],
            [
              <p className="text-copy-mobile md:text-copy">
                yep, you heard us right{" "}
              </p>,
              <p className="text-emphasis-mobile md:text-emphasis">
                4 matches
              </p>,
              <p className="text-copy-mobile md:text-copy">
                2 weeks. 4 matches. ending on valentines day.
              </p>,
            ],
            [
              <p className="text-left text-answer-mobile md:text-answer">
                take 10 minutes to quickly (but thoughtfully) fill out this
                survey, and we’ll find your top most 4 compatible matches.
              </p>,
              <p className="text-emphasis-mobile md:text-emphasis">
                the rest is up to you ;)
              </p>,
            ],
            [
              <p className="text-left text-answer-mobile md:text-answer">
                Your answers are
                <span className="text-emphasis-sm-mobile md:text-emphasis-sm">
                  {" "}
                  totally private
                </span>
                , not even your match will see them. So honesty can only help
                you!
              </p>,
            ],
            [
              <p className="text-copy-mobile md:text-copy">
                let's get started!
              </p>,
            ],
          ]}
        ></TextPlayer>
        <Survey
          className={showSurvey ? "animate-fade-in" : "hidden"}
          username={username}
          sessionId={sessionId}
          questions={questions}
          initialAnswers={initialAnswers}
        ></Survey>
      </div>
    </Layout>
  )
}
